/*!
 =========================================================
 * HubEE JS Layout - v1.0.0
 =========================================================
 */
$( document ).ready(function() {
        setTimeout(function() {
        }, 100),
		
		/* Search input */
        // $(".search-icon").click(function() {
        //     $(this).parent().parent().addClass("active")
        // }),
        // $(".search-wrapper .close").click(function() {
        //     $(this).parent().removeClass("active")
        // }),
        /* Sidebar menu */
		// $(".close-sidebar-btn").click(function() {
            
        //     $(this).toggleClass("is-active");
		// 	$(".app-container").toggleClass("closed-sidebar");
        // }),
		
		
        $(".mobile-toggle-nav").click(function() {
            $(this).toggleClass("is-active"),
            $(".app-container").toggleClass("sidebar-mobile-open")
        }),
        $(".mobile-toggle-header-nav").click(function() {
            $(this).toggleClass("active"),
            $(".app-header__content").toggleClass("header-mobile-open")
        }),
        $(window).on("resize", function() {
            $(this).width() < 1250 ? $(".app-container").addClass("closed-sidebar-mobile closed-sidebar") : $(".app-container").removeClass("closed-sidebar-mobile closed-sidebar")
        }),
		
		/* Header resize on scroll */
		$(window).scroll(function() {    
			var scroll = $(window).scrollTop();
			if (scroll >= 100) {
				$(".app-container").addClass("header-reduced");
			} else {
				$(".app-container").removeClass("header-reduced");
			}
		})
		
});
